import api from "../axios_service.js";

export async function fetchClientes(filtros) {
  const response = await api.get(`/gestor/clientes${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca um cliente
export async function fetchCliente(id) {
  const response = await api.get(`/gestor/clientes/${id}`);
  return response.data.result;
}
// Busca um cliente
export async function fetchClienteColaboradoresAtividade(atividade_id) {
  const response = await api.get(
    `/gestor/clientes-colaboradores-atividade/${atividade_id}`
  );
  return response.data.result;
}

// Atualiza um cliente existente
export function putCliente(id, cliente) {
  let body = {};
  for (let key in cliente) {
    body[key] = cliente[key];
  }
  return api.put(`/gestor/clientes/${id}`, body);
}

// Cria um cliente novo
export async function postCliente(cliente) {
  const response = await api.post("/gestor/clientes/add", cliente);
  return response;
}

export async function uploadLogo(id, fd) {
  const response = await api.post(`/gestor/clientes/${id}/logo`, fd);
  return response;
}

export async function fetchClienteColaboradores(cliente_id) {
  const response = await api.get(
    `/gestor/clientes-colaboradores/${cliente_id}`
  );
  return response.data.result;
}
